<template>
    <kinesis-container>
        <section id="ca-bouge" class="show_animBloc" v-view="fadeAnimBloc1" :class="{'fade-up': show_animBloc1}">
            <div class="img-left">
                <div class="desk-img">
                    <figure><kinesis-element :strength="10"><img :src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-1.jpg"' :srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-1-retina.jpg 2x"' alt="desk-imgBouge-1"></kinesis-element></figure>
                    <figure><kinesis-element :strength="30"><img :src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-2.jpg"' :srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-2-retina.jpg 2x"' alt="desk-imgBouge-2"></kinesis-element></figure>
                </div>
                <div class="mob-img">
                    <figure style="width: 178px;"><img :src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-1.jpg"' alt="mob-imgBouge-1"></figure>
                    <figure style="width: 202px;"><img :src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-3.jpg"' alt="mob-imgBouge-3"></figure>
                </div>
            </div>

            <div class="sp-ParentBloc">
                <h4 class="title-h4">{{$t('dataBouge')['0']}}</h4>
                <div class="sp-title">
                    <h2 class="title-h2">{{$t('dataBouge')['1']}} <br>{{$t('dataBouge')['2']}}</h2>
                    <p>
                        <strong>{{$t('dataBouge')['3']}}</strong> {{$t('dataBouge')['4']}}
                    </p>
                    <p>{{$t('dataBouge')['5']}} <strong>{{$t('dataBouge')['6']}}</strong>{{$t('dataBouge')['7']}}</p>
                </div>
            </div>

            <div class="img-right">
                <div class="desk-img">
                    <figure><kinesis-element :strength="20"><img :src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-3.jpg"' :srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-3-retina.jpg 2x"' alt="desk-imgBouge-3"></kinesis-element></figure>
                    <figure><kinesis-element :strength="30"><img :src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-4.jpg"' :srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-4-retina.jpg 2x"' alt="desk-imgBouge-4"></kinesis-element></figure>
                </div>
                <div class="mob-img">
                    <figure style="width: 202px;"><img :src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-2.jpg"' alt="mob-imgBouge-2"></figure>
                    <figure style="width: 177px;"><img :src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-4.jpg"' alt="mob-imgBouge-4"></figure>
                </div>
            </div>
        </section>
    </kinesis-container>
</template>

<script>
    import { KinesisContainer, KinesisElement} from 'vue-kinesis'
    export default {
        name: 'SaintOuen6',
        props: {
            msg: String,
        },
        data:()=>({
            show_animBloc1: false,
        }),
        components:{
            KinesisContainer,
            KinesisElement
        },
        methods:{
            fadeAnimBloc1(e){
                if(e.type == "progress" && e.percentInView >= 0.2){
                    this.show_animBloc1 = true
                }
            }
        }
    }
</script>
